$(document).ready(function () {
  $(function () {
    function initBannerCarousel() {
      const bannerList = window.banner;
      const indexContainer = $("#index-banner.swiper-wrapper");
      const isLoggedIn = $(".before-login.hide").length !== 0;

      bannerList &&
        bannerList.length !== 0 &&
        bannerList.forEach(function (item) {
          const { imageUrl, redirectUrl, redirectGroup, redirectPage, bannerType } = item;

          if (imageUrl) {
            /**
             * Redirect Group Option
             * 0 - Promo
             * 1 - Join Us
             * 2 - Lottery
             * 3 - Product
             * 4 - Others inner page (Loyalty, Customer Service)
             * 5 - 自定义
             */
            const banner = $(`
            <div class="swiper-slide" data-swiper-autoplay="5000">
                <img ${redirectUrl ? `data-redirect=${redirectUrl}` : ""} class="img" src="${imageUrl}"/>
                <div class="img-bg" style="background-image: url(${imageUrl})"></div>
            </div>
          `);

            banner.click(() => {
              if (redirectGroup === 1) {
                hezuo();
              } else if (redirectGroup === 4 && redirectUrl === "/faq") {
                answer();
              } else {
                window.initBannerCarouselRedirection({
                  redirectUrl,
                  redirectGroup,
                  redirectPage,
                  isLoggedIn,
                });
              }
            });

            /**
             * index append 首页type banner, promo append 优惠活动type banner
             * 轮播图类型 bannerType
             * 0 - 首页
             * 1 - 优惠活动
             * 2 - 其他
             */
            if (bannerType === 0) {
              indexContainer.append(banner);
            }
          }
        });

      if (bannerList && bannerList.length) {
        var mySwiper = new Swiper(".swiper-container.banner", {
          speed: 400,
          autoplay: {
            delay: 5000,
          },
          loop: true,
          // If we need pagination
          pagination: {
            el: ".swiper-pagination",
          },
        });
      }
    }

    if (Pace) {
      Pace.on("done", initBannerCarousel);
    } else {
      $(window).load(initBannerCarousel);
    }
  });

  function hezuo() {
    layer.open({
      type: 1,
      title: '<span class="gundong"></span>代理合作',
      shadeClose: true,
      shade: 0.8,
      area: ["630px", "85%"],
      content: $("#m1"),
    });
  }
  function youhui() {
    layer.open({
      type: 1,
      title: '<span class="gundong"></span>最新优惠',
      shadeClose: true,
      shade: 0.8,
      area: ["630px", "85%"],
      content: $("#youhui"),
    });
  }
  function brandAdvertise() {
    // window.open("https://vcr.a0404.com/", "_blank");
  }
  function answer() {
    layer.open({
      type: 1,
      title: '<span class="gundong"></span>常见问题',
      shadeClose: true,
      shade: 0.8,
      area: ["630px", "80%"],
      content: $("#m2"),
    });
  }
  function about() {
    layer.open({
      type: 1,
      title: '<span class="gundong"></span>关于我们',
      shadeClose: true,
      shade: 0.8,
      area: ["630px", "80%"],
      content: $("#m3"),
    });
  }

  $(".hezuo").click(function (e) {
    e.preventDefault();
    hezuo();
  });
  $(".youhui").click(function (e) {
    e.preventDefault();
    youhui();
  });
  $(".answer").click(function (e) {
    e.preventDefault();
    answer();
  });
  $(".about").click(function (e) {
    e.preventDefault();
    about();
  });

  $(".ss").click(function (e) {
    e.preventDefault();
    brandAdvertise();
  });
  $(".forgot-pwd").click(function (e) {
    e.preventDefault();
    alert("帐户密码遗失请与在线客服联系！");
  });

  $(".brandAdvertise").click(function (e) {
    // e.preventDefault();
    brandAdvertise();
  });

  // Promotion loader
  $(function () {
    if (window.promo) {
      renderPromo(window.promo);
    } else {
      Object.defineProperty(window, "promo", {
        set: function (data) {
          renderPromo(data);
        },
      });
    }

    function registerPromo(promoId) {
      api.registerPromo(promoId);
    }

    function renderPromo(promoData) {
      if (Array.isArray(promoData)) {
        var promoWrapper = $("#promodiv");
        promoData.forEach(function (value, index) {
          var promoItem = $(`<div class="contentbox" data-type="${value.type}"></div>`);

          var promoId = value.promoId;
          var requestToJoin = value.requestToJoin;
          var firstDepositPromoJoinMsg = value.firstDepositPromoJoinMsg;

          if (value.imagePath) {
            promoItem.append(
              $(`
                  <div class="actimg tc">
                    <img src="${value.imagePath}"/>
                  </div>
                  <div class="actcont"><div class="commontitle">
                  ${value.content}
                  ${
                    requestToJoin
                      ? `
                      <div
                        class="promo--modal--footer"
                        style="display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; height: 50px;"
                      >
                        ${
                          firstDepositPromoJoinMsg
                            ? `
                              <div class="promo--modal--footer--info" style="color: #999;">
                                ${firstDepositPromoJoinMsg}
                              </div>
                            `
                            : `
                              <button
                                class="promo--modal--footer--register-promo"
                                data-id="${promoId}"
                                style="cursor:pointer;padding: 10px 20px;outline: none;width: 100px; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; background-color: #ff5100; color: #fff;border-radius: 4px; border: 1px solid #ff3300;"
                              >
                                申请参与
                              </button>
                              `
                        }
                          </div>
                        `
                      : ``
                  }
                  </div></div>
              `)
            );
          } else {
            promoItem.append(
              $(`
                  <div class="actimg tc">${value.title}</div>
                  <div class="actcont">${value.content}</div>
              `)
            );
          }

          promoWrapper.append(promoItem);
        });

        $(function () {
          $(".promo--modal--footer--register-promo").on("click", function (e) {
            e.preventDefault();
            var promoId = $(this).data("id");
            registerPromo(promoId);
          });
        });

        $(".promo-standalone .activitycont").html(promoWrapper);
      }

      $(".promo-standalone .activitytab .tab-item").click(function () {
        var index = $(this).index();
        var dataType = $(this).data("type");

        $(this).siblings().removeClass("active");
        $(this).addClass("active");

        if (dataType === -1) {
          $(".contentbox").slideDown();
        } else {
          $('.contentbox[data-type="' + dataType + '"]').slideDown();
          $('.contentbox:not([data-type="' + dataType + '"])').slideUp();
        }

        loadPromoAnimation();
      });

      $(document).on("click", ".contentbox .actimg", function (e) {
        e.preventDefault();
        $(this).siblings(".actcont").slideToggle();
        $(this).parents(".contentbox").siblings(".contentbox").children(".actcont").slideUp();
      });

      $(document).on("click", ".kf_t .with-desc", function (e) {
        e.preventDefault();
        $(this).find(".img-desc").fadeToggle();
      });
    }
  });
});
